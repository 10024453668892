import React from 'react';
import Layout from 'components/layout'
import _get from 'lodash/get';
import * as styles from 'pages/pages.module.css';
import NotFoundImage from 'components/404/NotFoundImage';
import withLocation from 'helpers/withLocation';
import { FormattedMessage } from 'react-intl';

class NotFoundPage extends React.Component {
  
  render () {
    return (
      <Layout lang={_get(this.props, 'pageContext.lang', 'en')} withHeader={true} withIntercom={false} pageName="404" registrationURL={_get(this.props, 'pageContext.registrationURL', '')}>
        <section className={[styles.content, styles.top, styles.notFound].join(' ')}>
          <div className={[styles.wrapper, styles.wrapperNotFound].join(' ')}>
            <div className={styles.intro}>
              <div className={styles.msg}>
                <h1 className={styles.accent}>
                  <FormattedMessage id="Page404.title" defaultMessage="We're sorry, but the page can't be found!" />
                </h1>
                <h2 className={styles.subheader}>
                  <FormattedMessage id="Page404.subtitle" defaultMessage="The link may be broken or the page has been removed." />
                </h2>
              </div>
              <div>
                <a
                  href="/"
                  className={styles.backToMain}
                >
                  <FormattedMessage id="Page404.backToMain" defaultMessage="Back to Main" />
                </a>
              </div>
            </div>
            <NotFoundImage />
          </div>
        </section>
      </Layout>
    )
  }
}

export default withLocation(NotFoundPage);
